import React, { useEffect, useState } from 'react';
import Logo from '../img/Logo.png';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import apiService from '../services/index'; // Adjust the import path to your service file

const Footer = () => {
    const [totalVisitors, setTotalVisitors] = useState(0); // State for visitor count
    const currentYear = new Date().getFullYear();
    const productUuid = "9654954f-7f82-4651-9252-6b017b71ccf2"; // Replace with the actual product_uuid
    const [activeLink, setActiveLink] = useState('');

    const handleNavbarLinkClick = (section) => {
        setActiveLink(section);
        document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleFooterLinkClick = (section) => {
        setActiveLink(section);
        document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', `#${section}`);
    };

    useEffect(() => {
        // Fetch total visitor count using getcount method
        const fetchVisitorCount = async () => {
            try {
                const response = await apiService.getcount(productUuid); // Pass the product_uuid
                if (response.status === 200) {
                    setTotalVisitors(response.data.visit_count); // Update with the visit_count from the response
                } else {
                    console.error('Error fetching visitor count:', response.status);
                }
            } catch (error) {
                console.error('Error fetching visitor count:', error);
            }
        };
        const patchVisitorCount = async () => {
            try {
                const response = await apiService.patchcount(productUuid); // Pass the product_uuid
                if (response.status === 200) {
                    // setTotalVisitors(response.data.visit_count); // Update with the visit_count from the response
                } else {
                    console.error('Error fetching visitor count:', response.status);
                }
            } catch (error) {
                console.error('Error fetching visitor count:', error);
            }
        };

        fetchVisitorCount();
        patchVisitorCount()
    }, []); // Run effect only once when the component mounts

    return (


        <div>
            <div className="footer-area style-one">
                <div className="container">
                    <div className="row g-4"> {/* Used `g-4` for consistent spacing */}
                        <div className="col-md-4 col-12">
                            <div className="footer-logo">
                                <a href="/">
                                    <img src={Logo} width={70} alt="Logo" />
                                </a>
                            </div>
                            <div className="footer-widget-desc">
                                <p>
                                    Let us take care of your responsibilities in Tamil Nadu while you focus on your dreams. Trust Caring NRI because we care!
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="footer-widget-content my-2">
                                <div className="footer-widget-title">
                                    <h4>Useful Links</h4>
                                </div>
                                <div className="footer-widget-menu">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a
                                                href="#home"
                                                className={activeLink === 'home' ? 'active' : ''}
                                                onClick={() => handleFooterLinkClick('home')}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#about"
                                                className={activeLink === 'about' ? 'active' : ''}
                                                onClick={() => handleFooterLinkClick('about')}
                                            >
                                                About
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#services"
                                                className={activeLink === 'services' ? 'active' : ''}
                                                onClick={() => handleFooterLinkClick('services')}
                                            >
                                                Services
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#contact"
                                                className={activeLink === 'contact' ? 'active' : ''}
                                                onClick={() => handleFooterLinkClick('contact')}
                                            >
                                                Contact
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="footer-widget-content">
                                <div className="footer-widget-title">
                                    <h4>Our Services</h4>
                                </div>
                                <div className="footer-widget-menu">
                                    <ul className="list-unstyled"> {/* Removed default padding */}
                                        <li><a href="#service2">Elderly Care</a></li>
                                        <li><a href="#service2">Property Management Services</a></li>
                                        <li><a href="#service2">Tax Management</a></li>
                                        <li><a href="#service1">Certification Assistance</a></li>
                                        <li><a href="#service1">Destination Management</a></li>
                                        <li><a href="#service1">Legal Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-4">
                        <div className="footer-bottom-area">
                            <div className="row">
                                <div className="col-md-6 col-12 text-start"> {/* Ensure left alignment */}
                                    <div className="footer-bottom-content">
                                        <p>
                                            Copyrights © {currentYear} Design & Developed By
                                            <span style={{ marginLeft: '5px' }}>
                                                <a href="https://tagcs.in/" className="footer-link" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    TAGCS
                                                </a>
                                            </span>.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-md-6 col-12 text-end"> {/* Ensure right alignment */}
                                    <div className="footer-botton-social-icon">
                                        <ul className="d-flex justify-content-end gap-3 list-unstyled">
                                            <li><a href="#" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookF} size="1x" /></a></li>
                                            <li><a href="#" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} size="1x" /></a></li>
                                            <li><a href="#" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} size="1x" /></a></li>
                                            <li><a href="#" aria-label="YouTube"><FontAwesomeIcon icon={faYoutube} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="view-count-section mt-3">
                        <p className="text-white text-start">Total Views: {totalVisitors}</p> {/* Left aligned */}
                    </div>
                </div>
            </div>

            <a href="https://wa.me/918925153800" target="_blank" rel="noopener noreferrer" className="whatsapp-float" aria-label="WhatsApp">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
        </div>


    );
};

export default Footer;
