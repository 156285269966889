import axios from "axios";
import { API_URL } from "../constant";

export default {
    getcount: (product_uuid) => {
        return axios.get(`${API_URL.COUNT_GETALL}/${product_uuid}`, {
            headers: { "Content-Type": "application/json" },
            // withCredentials: true,
        });
    },
    
    patchcount: (product_uuid) => {
        return axios.patch(`${API_URL.COUNT_GETALL}/${product_uuid}`,{}, {
            headers: { "Content-Type": "application/json" },
            // withCredentials: true,
        });
    },
    
    sendEmail: (emailData) => {
        return axios.post(API_URL.SEND_EMAIL, emailData, {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true,
        });
      },
    

};
