import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import '../src/css/home.css';
import Home from './pages/Home';
import Footer from './pages/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ViewCountProvider } from './context/ViewCountContext';
function App() {
  return (
    <ViewCountProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ViewCountProvider>
  );
}

export default App;
