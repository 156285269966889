import React, { createContext, useState, useEffect } from 'react';

export const ViewCountContext = createContext();

export const ViewCountProvider = ({ children }) => {
    const [viewCount, setViewCount] = useState(0);  // Track session views (per user)
    const [totalVisitors, setTotalVisitors] = useState(0);  // Track global total visitors

    useEffect(() => {
        // Retrieve the total visitor count from localStorage (if any)
        const storedTotalVisitors = localStorage.getItem('totalVisitors');
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

        // Initialize the total visitor count from localStorage
        if (storedTotalVisitors) {
            setTotalVisitors(Number(storedTotalVisitors));
        }

        // Check if the user has visited before (session-based tracking)
        if (!hasVisitedBefore) {
            // First-time visit: increment the total visitors count
            const updatedTotalVisitors = Number(storedTotalVisitors || 0) + 1;

            // Store the updated count in localStorage
            localStorage.setItem('totalVisitors', updatedTotalVisitors);

            // Mark as visited to prevent incrementing the count for this session
            localStorage.setItem('hasVisitedBefore', 'true');
        }

        // Track the session view count (one per user session)
        const sessionCount = localStorage.getItem('sessionCount');
        if (!sessionCount) {
            // First-time visit in this session
            localStorage.setItem('sessionCount', '1');  // Set session count
            setViewCount(1);  // Set the session view count to 1
        } else {
            setViewCount(Number(sessionCount));  // Keeps track of page reloads in the session
        }
    }, []);  // Empty dependency array ensures this effect runs only on mount

    return (
        <ViewCountContext.Provider value={{ viewCount, totalVisitors }}>
            {children}
        </ViewCountContext.Provider>
    );
};
